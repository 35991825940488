var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-card"},[_c('div',{staticClass:"box-content"},[_c('div',{staticClass:"p-content d-flex align-items-center hover"},[_c('span',{staticClass:"number-circle"},[_vm._v(_vm._s(_vm.index + 1))]),_c('div',{staticClass:"d-flex flex-column justify-content-between h-100 overflow-hidden mx-3"},[_c('div',{staticClass:"content-info name font14 line-height18",on:{"click":function($event){return _vm.$router.push(`/sub-community/governance/detail/${_vm.proposalItem.id}`)}}},[_vm._v(" "+_vm._s(_vm.getName)+" ")]),_c('div',{staticClass:"content-info font16 line-height20 font-bold",staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.$router.push(`/sub-community/governance/detail/${_vm.proposalItem.id}`)}}},[_vm._v(" "+_vm._s(_vm.proposalItem.title)+" ")])])]),_c('div',{staticClass:"other-info d-flex justify-content-between"},[_c('div',{staticClass:"font18 d-flex justify-content-between info-start font14 line-height20"},[_c('div',{staticStyle:{"color":"#50BF00","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t('nps.proposalAgreeBtn'))+": "+_vm._s(_vm.proposalItem.voteAgreeTotalScore || 0))]),_c('div',{staticStyle:{"color":"#FF5B4D","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t('nps.proposalDisagreeBtn'))+": "+_vm._s(_vm.proposalItem.voteDisagreeTotalScore || 0))])]),_c('div',{staticClass:"d-flex align-items-end info-end"},[_c('div',{staticClass:"t-flag px-1 font14 line-height16 py-1",class:_vm.proposalItem.status == 0
            ? 'proposal-pending'
            : _vm.proposalItem.status == 1
            ? 'proposal-rolling'
            : _vm.proposalItem.proposalResult === 1
            ? 'proposal-pass'
            : 'proposal-unpass'},[_vm._v(" "+_vm._s(_vm.proposalItem.status == 0 ? _vm.$t("nps.propsalVoteStatusWaitStart") : _vm.proposalItem.status == 1 ? _vm.$t("nps.propsalVoteStatusDoing") : _vm.proposalItem.proposalResult === 1 ? _vm.$t("nps.pass") : _vm.$t("nps.unpass"))+" ")]),_c('div',{staticClass:"w-auto mt-1 text-grey-7 font12 line-height20",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t("nps.proposalEnd") + ":" + _vm.endTime)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SetCommunityProfile.vue?vue&type=template&id=6816d8fc&scoped=true&"
import script from "./SetCommunityProfile.vue?vue&type=script&lang=js&"
export * from "./SetCommunityProfile.vue?vue&type=script&lang=js&"
import style0 from "./SetCommunityProfile.vue?vue&type=style&index=0&id=6816d8fc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6816d8fc",
  null
  
)

export default component.exports